import React from "react"
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom"
import "./App.css"
import blobs from "./components/blobs"
import Navbar from "./components/common/Navbar"
import Home from "./pages/Home"
import Blog from "./pages/Blog"
import BlogDetails from "./pages/BlogDetails"
import News from "./pages/News"
import Contact from "./pages/Contact"
import Terms from "./pages/Terms"
import Privacy from "./pages/Privacy"
import Cookies from "./pages/Cookies"
import ScrollToView from "./utilities/ScrollToView"

function App() {

  return (
    <Router>
      <ScrollToView />
      <div className="relative overflow-x-hidden">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/blog" element={<Blog />}/>
          <Route path="/blog/:subject" element={<Blog />}/>
          <Route path="/blog/:subject/:slug" element={<BlogDetails />}/>
          <Route path="/contact" element={<Contact />}/>
          <Route path="/privacy" element={<Privacy />}/>
          {/* <Route path="/news" element={<News />}/> */}
          <Route path="/terms" element={<Terms />}/>
          <Route path="/cookies" element={<Cookies />}/>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {/* {blobs.map((blob) => blob)} */}
      </div>
    </Router>
  )
}

export default App
