import React, { useEffect, useState } from 'react'
import { blogCardsDetails, blogItems } from '../constants'
import { ReactComponent as RightArrow } from '../assets/icons/rightArrow.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Newsletter from '../components/Newsletter'
import Icon from '../components/common/Icon'


const MajorBlogCard = ({ image, subject, title, description }) => {
    return (
        <div className='w-[85%] h-[68vh] bg-white mt-20 lg:flex hidden rounded-md' >
            <Icon name={image} className='max-h-full w-[60%] rounded-md' png />
            <div className='h-[100%] w-[40%] flex flex-col justify-between p-10'>
                <div className='space-y-5'>
                    <h2 className='text-black'>
                        <span className='text-white bg-[#111111] py-2 px-3 rounded-sm mr-3'>{subject}</span>
                        5 min read
                    </h2>
                    <h1 className='text-4xl font-bold text-black'>{title}</h1>
                    <p className='text-[#111111]'>
                        {description}
                    </p>
                </div>
                <div>
                    <Link to={`/blog/${subject}/${title}`} className='px-4 py-2 text-black border-[1.5px] font-bold border-black rounded-sm flex items-center space-x-2' > <span>Read more</span> <RightArrow /> </Link>
                </div>
            </div>
        </div>
    )
}

export const BlogCard = ({ image, subject, title, description }) => {
    const maxLength = 142

    return (
        <div className='lg:w-[26.5vw] h-[75vh] border-2 rounded-md max-w-4xl' >
            <Icon name={image} className='w-full h-[50%] aspect-auto' png />
            <div className='h-[50%] w-full bg-white flex flex-col justify-between p-5'>
                <div className='space-y-2'>
                    <h2 className='text-black'>
                        <span className='text-white bg-[#111111] py-1 px-2 rounded-sm mr-3'>{subject}</span>
                        5 min read
                    </h2>
                    <h1 className='text-xl font-bold text-black'>{title}</h1>
                    <p className='text-[#111111] text-sm'>{description.length > maxLength ? `${description.slice(0, maxLength)}...` : description}</p>
                </div>
                <div>
                    <Link to={`/blog/${subject}/${title}`} className='px-4 py-2 text-black border-[1.5px] border-black rounded-sm flex items-center space-x-2 font-bold' ><span>Read more</span> <RightArrow /> </Link>
                </div>
            </div>
        </div>

    )
}

const Blog = () => {
    const navigate = useNavigate()
    const subject = useParams()?.subject
    const [blogItem, setBlogItem] = useState(subject || blogItems[0].value)
    const [blogCards, setBlogCards] = useState(blogCardsDetails)
    useEffect(() => {
        if (blogItem === 'all') { setBlogCards(blogCardsDetails) }
        else {
            setBlogCards(blogCardsDetails.filter((blog) => {
                return blog.subject.toLowerCase() === blogItem ? true : false
            }))

        }
    }, [blogItem])
    return (
        <section className='w-full min-h-[100vh] flex flex-col items-center mt-5 mb-[20vmin]'>
            <div className='w-full flex flex-col items-center text-center space-y-8'>
                <h1 className=' text-4xl lg:text-6xl'>Meddling in Meliorism</h1>
                <p className='w-[90%] lg:w-[30%]'>Peek into how we're shaping solutions for the future, discover our latest product updates, partnership announcements, user stories, and more.</p>
                <div className='flex bg-white p-[5px] space-x-1 w-[90%] lg:w-auto overflow-x-scroll lg:overflow-x-hidden rounded-md h-10'>
                    {blogItems.map((item) => {
                        return (
                        <button
                            key={item.key}
                            onClick={() => {
                            setBlogItem(item.value)
                            navigate(item.value !== 'all' ? `/blog/${item.value.toLowerCase()}` : '/blog')
                            }}
                            className={`py-1 rounded-[3px] px-3 text-sm focus:outline-none whitespace-nowrap ${blogItem === item.value ? 'bg-[#444BFF] text-white' : 'text-black'}`} >{item.title}</button>)
                    })}

                </div>
            </div>
            {<MajorBlogCard {...blogCards[0]} />}
            <div className='my-16 w-[85%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-12'>
            {
                blogCards.slice(1).map((item) => {
                    return (<BlogCard {...item} />)
                })
            }
            </div>
            <Newsletter />

        </section>
    )
}

export default Blog
