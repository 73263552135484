import React from 'react'
import { ReactComponent as ViewsIcon } from '../assets/icons/viewsIcon.svg'
import { ReactComponent as FacebookIcon } from '../assets/icons/facebook.svg'
import { ReactComponent as TwitterIcon } from '../assets/icons/twitter.svg'
import { ReactComponent as InstaIcon } from '../assets/icons/instagram.svg'
import { blogCardsDetails, tempComments } from '../constants'
import { BlogCard } from './Blog'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrumb from '../components/common/Breadcrumb'
import Icon from '../components/common/Icon'

const RecommendedPosts = ({ except }) => {
    return (
        <div className='hidden lg:flex flex-col gap-3 items-center'>
            <h1 className='text-xl font-bold'>Recommended posts for you</h1>
            {blogCardsDetails.filter((blog) => {
                return blog.title === except ? false : true
            }).map((item, index) => {
                if (index > 1) { return null }
                return <BlogCard {...item} />
            })}
        </div>
    )
}

const TempData = () => {
    return (
        <div className='space-y-3'>
            <h2 className='font-bold text-xl'>There should be a heading here</h2>
            <p className='text-lg'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius, optio dolorem quae minus perspiciatis quos obcaecati omnis totam natus minima provident iure maxime pariatur cumque deleniti id inventore, tempora neque accusantium. Adipisci cupiditate aspernatur expedita quidem tenetur. Tenetur molestias ratione odio, sed assumenda obcaecati illum amet recusandae labore temporibus cum esse sit cupiditate, atque sint dolor qui in culpa pariatur expedita magni. Cupiditate qui sunt sed quae placeat, animi aspernatur officiis eaque, magnam vitae cum atque, reiciendis aliquid est maiores eligendi ratione ipsa quas architecto quasi quia ullam. Incidunt laudantium odit quisquam rem sapiente distinctio expedita impedit ut autem sint!</p>
        </div>
    )
}

const Comment = ({ profileImage, name, comment }) => {
    return (
        <div className='w-full rounded-lg my-3 flex p-3 lg:p-6 overflow-hidden bg-[#393939] h-32'>
            <Icon name={profileImage} className='w-12 h-12 rounded-full' png />
            <div className='w-[75%] flex flex-col mx-4 h-full'>
                <h2 className='font-bold'>{name}</h2>
                <p className='text-sm lg:text-[1rem] mt-2'>{comment}</p>
            </div>
        </div>
    )
}

const BlogDetails = () => {
    window.scrollTo(0,200) 
    const navigate = useNavigate()
    const {subject, slug} = useParams()
    const heroImage = blogCardsDetails.filter((b)=>{
        return b.title === slug ? true :false
    })[0]?.image || 'diceImage'
    return (
        <section className=' w-full min-h-[100vh] my-10 flex flex-col items-center'>
            <Breadcrumb address={`Blog/${subject?.charAt(0).toUpperCase() + subject?.slice(1)}/${slug}`} />
            <div className='w-[90%] lg:w-[80%] flex flex-col my-5 items-center py-5 px-3'>
                <h1 className='font-bold text-4xl'>{ slug || 'Your passport to the web3 economy'}</h1>
                <div className='w-full flex flex-col lg:flex-row gap-4 justify-between mt-16 mb-10 lg:mb-0'>
                    <div className=' flex gap-5'>
                        <span>Apr 17, 2023</span>
                        <span className='flex items-center gap-2'><ViewsIcon /> <span>315 views</span></span>
                    </div>
                    <div className='flex'>
                        <span>Share with friends:</span>
                        <div className="flex gap-2 mx-3">
                            <FacebookIcon />
                            <TwitterIcon />
                            <InstaIcon />
                        </div>
                    </div>
                </div>
                <Icon name={heroImage} className='w-full aspect-auto mt-5 mb-16' png />

                <div className='flex justify-between w-full' id='content'>
                    <div className='flex flex-col lg:w-[50%] space-y-10 relative'>
                        {[1, 2, 3].map((elem) => {
                            return <TempData key={elem} />
                        })}
                        <h2 className='font-bold text-2xl w-full'>Author - Techievena</h2>
                    </div>
                    <RecommendedPosts except={slug} />
                </div>

                <div id='comment_section' className='w-full my-20'>
                    <h2 className='font-bold text-lg'>{tempComments?.length + ' '}COMMENTS</h2>
                    {tempComments.map((comment) => {
                        return <Comment {...comment} />
                    })}
                    <div className='flex lg:flex-row flex-col w-full mt-16 gap-8 h-14'>
                        <input placeholder='Put in your comments and feedback' className='bg-white text-black lg:w-[100%] text-left text-lg p-3 rounded-md' />
                        <button onClick={() => navigate('/')} className='text-white flex items-center justify-center border-2 border-[#3588D6] lg:p-5 p-4 rounded-md w-[100%] lg:w-[40%] font-bold' >LEAVE A COMMENT</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogDetails
