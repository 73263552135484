export const blogItems = [
    {
        key:1,
        title:'View all',
        value:'all'
    },
    {
        key:2,
        title:'Crypto',
        value:'crypto'
    },
    {
        key:3,
        title:'Blockchain',
        value:'blockchain'
    },
    {
        key:4,
        title:'Announcements',
        value:'announcements'
    },
    {
        key:5,
        title:'People',
        value:'people'
    },
    {
        key:6,
        title:'Engineering',
        value:'engineering'
    },
    {
        key:7,
        title:'NFT',
        value:'nft'
    },
]

export const blogCardsDetails = [
    {
        key:1,
        image:'diceImage',
        subject:'Crypto',
        title:'Your passport to the web3 economy',
        description:'If you’ve read this far and you’re wondering what “web3” is exactly, this is one of those need-to-knows, and it’s pretty simple. We’ll explain more below, but in short web3 is the next era of the internet in which blockchain technology will play a central role.'
    },
    {
        key:2,
        image:'blockchain',
        subject:'Blockchain',
        title:'A beginner’s guide to blackchain for engineers',
        description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa soluta blanditiis, molestiae pariatur mollitia iure.'
    },
    {
        key:3,
        image:'people',
        subject:'People',
        title:'How to secure have your crypto wallet',
        description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa soluta blanditiis, molestiae pariatur mollitia iure.'
    },
    {
        key:4,
        image:'nft',
        subject:'NFT',
        title:'New NFT projects to watch : December 2022',
        description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa soluta blanditiis, molestiae pariatur mollitia iure.'
    },
    {
        key:5,
        image:'engineering',
        subject:'Engineering',
        title:'What is a Decentralized Autonomous Organization',
        description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa soluta blanditiis, molestiae pariatur mollitia iure.'
    },
    {
        key:6,
        image:'crypto',
        subject:'Crypto',
        title:'Crypto state of play : September 2022',
        description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa soluta blanditiis, molestiae pariatur mollitia iure.'
    },
    {
        key:7,
        image:'blockchain2',
        subject:'Blockchain',
        title:'Guide to buy cryptocurrency safely : September 2022',
        description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa soluta blanditiis, molestiae pariatur mollitia iure.'
    },
]

export const tempComments = [
    {
        key:1,
        name:'Ashok Kumar',
        comment:'Quite insightful!',
        profileImage: "ashok-kumar"
    },
    {
        key:2,
        name:'Rahul Sharma',
        comment:'Great read',
        profileImage: "rahul-sharma"
    },
]